<template>
	<div v-if="domain">
		<nav class="navbar" role="navigation" aria-label="main navigation" v-if="!isIframe">
			<div class="container is-max-desktop">
				<div class="navbar-brand">
					<a class="navbar-item" :href="getUrl()">
						<img
							:src="getLogoUrl()"
							width="138"
							height="48"
							alt="GiftNFT"
						/>
					</a>
					<span v-if="domain == DOMAIN.HEDERA" class="logo-divider is-hidden-touch">X</span>
					<a v-if="domain == DOMAIN.HEDERA" class="navbar-item is-hidden-touch" :href="getUrl()">
						<img
							src="@/assets/img/hedera-logo-white.svg"
							width="148"
							alt="GiftNFT"
						/>
					</a>

					<a
						@click="toggleMobileMenu"
						role="button"
						class="navbar-burger burger"
						:class="{ 'is-active': mobileMenuOption }">
						<span aria-hidden="true"></span>
						<span aria-hidden="true"></span>
						<span aria-hidden="true"></span>
					</a>
				</div>

				<div class="navbar-menu" :class="{ 'is-active': mobileMenuOption }">
					<div class="navbar-end"  v-if="isValidClient">
						
						
						<div class="navbar-item" v-if="allowGiftNft">
							<a @click="childlogin(`/gift`, true)">Gift AN NFT</a>
						</div>
						<div class="navbar-item" v-if="allowGiftNft">
							<a @click="childlogin(`/gift`, true)">Gift Coins</a>
						</div>

						<div id="google_translate_element"></div>
					<div class="navbar-item has-dropdown" :class="{ 'is-active': isLanguageDropdownActive }" v-on-clickaway="away" translate="no">
						<a @click="isLanguageDropdownActive = !isLanguageDropdownActive" v-text="getSelectedLanguage()"></a>

						<div class="navbar-dropdown is-boxed is-right">
							<a class="navbar-item" @click="doGTranslate('en|en')">
								English
							</a>
							<a class="navbar-item" @click="doGTranslate('en|es')">
								Español
							</a>
							<a class="navbar-item" @click="doGTranslate('en|pt-PT')"> 
								Português (BR)
							</a>

						</div>
					</div>

					<div class="navbar-item has-dropdown" :class="{ 'is-active': isEndpointDropdownActive }" v-on-clickaway="awayEndpoint" translate="no">
						<a @click="isEndpointDropdownActive = !isEndpointDropdownActive" v-text="getSelectedEndpoint()"></a>

						<div class="navbar-dropdown is-boxed is-right">
							<a class="navbar-item" v-for="(e,idx) in waxEndpoints" v-bind:key="idx" @click="updateEndoint(e.endpoint)">
								{{ e.name }}
							</a>
						</div>
					</div>

						

						<nav-dropdown
							v-if="accountName && domainSettings"
							:account-name="accountName"
                            :isAdmin="isAdmin"
							:show-my-gifts="allowGiftNft"
                            :domain="domain"
                            :domainSettings="domainSettings"
							@logout="logout"
                            @toggleMobileMenu="toggleMobileMenu"
						/>

						<div class="navbar-item" v-if="accountName && domainSettings">
							<div class="row">
								<div class="columns is-multiline">
									<div class="column is-12 p-0 has-text-centered has-text-white">
										<span class="has-text-white has-text-weight-bold">RAM {{ramUsed}} KB / {{availableRam}} KB</span>
									</div>
									<div class="column is-12 p-0">
										<progress class="progress is-normal" :value="ramUsagePercentage" max="100">90%</progress>
									</div>
									<div class="column is-12 p-0 has-text-centered ">
										<span class="has-text-white has-text-weight-bold">{{ramUsagePercentage}}%</span>
									</div>
								</div>
							</div>
														
						</div>

						<div v-if="!accountName" class="navbar-item">
							<div class="buttons">
								<a
									class="button is-link is-rounded is-outlined is-uppercase has-text-weight-bold"
									@click="childlogin('')">
									Login
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</nav>
		<Uallogin v-if="domain == DOMAIN.WAX" ref="uallogin" @update-accountname="updateAccountName" @update-isAdmin="updateIsAdmin" @update-waxPermission="updateWaxPermission" @update-resources="updateResources"></Uallogin>
        <GybLogin v-if="domainSettings && (domain == DOMAIN.GYB || domain == DOMAIN.MEME || domain == DOMAIN.NEWDEX)" ref="gyblogin" @update-accountname="updateAccountName" :domainSettings="domainSettings" :domain="domain">
		</GybLogin>
        <HederaLogin v-if="domainSettings && (domain == DOMAIN.HEDERA)" ref="hederalogin" @update-accountname="updateAccountName" :domainSettings="domainSettings" :domain="domain">
		</HederaLogin>
		<router-view v-if="domainSettings" ref="templateData" :isAdmin="isAdmin" :domain="domain" :domainSettings="domainSettings" :accountName="accountName" :permission="waxPermission" :isValidClient="isValidClient" :giftClient="giftClient" @update-clientstatus="updateClientStatus"></router-view>
		<footer-section v-if="domainSettings && !isIframe" :domain="domain" :domainSettings="domainSettings"></footer-section>
		<div v-if="!isIframe" class="milky-way"></div>
		<div v-if="!isIframe" class="milky-way secondary"></div>
	</div>
</template>

<script>
import axios from "axios";
import FooterSection from "@/components/Footer.vue";
import Uallogin from "@/components/UalLogin.vue";
import UserGiftService from "./core/services/user-gift.service";
import NavDropdown from '@/components/NavDropdown.vue';
import GybLogin from '@/components/GybLogin.vue';
import HederaLogin from '@/components/HederaLogin.vue';
import { DOMAIN } from './core/models/constants'

export default {
    components: {
        FooterSection,
        Uallogin,
        NavDropdown,
        GybLogin,
        HederaLogin
    },
    data() {
        return {
            accountName: "",
            waxPermission:'active',
            mobileMenuOption: false,
            isValidClient: true,
            allowGiftNft: true,
            giftClient: null,
            domain: null,
            domainSettings: null,
            DOMAIN:DOMAIN,
            loadChildComponent:false,
			theme: '',
            isIframe: false,
            isAdmin: false,
			isLanguageDropdownActive: false,
			isEndpointDropdownActive: false,
			availableRam:'',
			ramUsagePercentage:'50',
			ramUsed:'',
			selectedLanguage: localStorage.getItem('language') ? localStorage.getItem('language') : 'English',
			selectedEndpoint: 'EOS Amsterdam',
			languages: [
						{
							lang: 'en',
							country: 'USA',
							name: 'English',
						},
						{
							lang: 'es',
							country: 'Spain',
							name: 'Spanish',
						},
						{
							lang: 'pt-PT',
							country: 'Brazil',
							name: 'Portuguese',
						},
					],
					waxEndpoints : [
		{
			name: 'EOS Amsterdam',
			endpoint: 'https://wax.eu.eosamsterdam.net'
		},
		{
			name: 'WAX Sweden',
			endpoint: 'https://api.waxsweden.org'
		},
		{
			name: 'Nefty Blocks',
			endpoint: 'https://wax-public1.neftyblocks.com'
		},
		{
			name: 'EOS DAC',
			endpoint: 'https://wax.eosdac.io'
		},
		{
			name: 'Aloha EOS',
			endpoint: 'https://api.wax.alohaeos.com'
		},
		{
			name: 'Crypto Lions',
			endpoint: 'https://wax.cryptolions.io'
		}
	]
        };
    },
    methods: {
		getSelectedLanguage(){
			return this.selectedLanguage
		},
		getSelectedEndpoint(){
			return this.selectedEndpoint
		},
		away() {
			this.isLanguageDropdownActive = false;
		},
		awayEndpoint() {
			this.isEndpointDropdownActive = false;
		},
        toggleMobileMenu() {
            this.mobileMenuOption = !this.mobileMenuOption;
        },
         async childlogin(view, toGift = false) {
            this.toggleMobileMenu()
            if(this.domain == DOMAIN.WAX){
                await this.$refs.uallogin.login(view);
            }
            else if(this.domain == DOMAIN.HEDERA){
                console.log(this.$refs)
                this.$refs.hederalogin.openLoginModal(view);
            }
            else{
                this.$refs.gyblogin.openLoginModal(view, toGift);
            }
        },
        updateAccountName(e) {
            this.accountName = e
        },
        updateIsAdmin(e) {
            this.isAdmin = e
        },
        updateWaxPermission(e) {
            this.waxPermission = e
        },
        updateClientStatus(e){
            this.isValidClient = e
        },
		updateResources(e){
			console.log(e)
			this.availableRam = e.availableRam
			this.ramUsagePercentage = e.ramUsagePercentage
			this.ramUsed = e.ramUsed

		},
        async transact(data) {
            if(this.domain == DOMAIN.WAX){
                //return await this.$refs.uallogin.transact(data);
               return await this.$refs.uallogin.transactAnnounceLink(data);
            }
            else if(this.domain == DOMAIN.HEDERA){
                return await this.$refs.hederalogin.transact(data);
            }
            else{
                return await this.$refs.gyblogin.transferNFT(data);
            }
        },
        async cancelGift(data) {
              return await this.$refs.uallogin.transactCancelLink(data);
        },
        async transactV2(data) {
            if(this.domain == DOMAIN.WAX){
                return await this.$refs.uallogin.transactV2(data);
            }
        },
        async associateHederaToken(tokenID){
            return await this.$refs.hederalogin.associateHederaToken(tokenID);
        },
        async claimLink(data){
            return await this.$refs.uallogin.transactClaimLink(data);
        },
        async logout(){
            if(this.domain == DOMAIN.WAX){
                await this.$refs.uallogin.logout();
            }
            else if(this.domain == DOMAIN.HEDERA){
                this.$refs.hederalogin.logout();
            }
            else{
                this.$refs.gyblogin.logout();
            }

        },
        getUrl(){
            if(this.$route.query.id){
                return `/?id=${this.$route.query.id}`
            }
            else if(this.$route.params.giftclientid){
                return `/?id=${this.$route.params.giftclientid}`
            }
            else{
                return '/'
            }
        },
        getLogoUrl(){
            switch (this.domain) {
                case DOMAIN.WAX:
				case DOMAIN.HEDERA:
                    return require(`@/assets/img/wax-themed-logo.svg`)
				case DOMAIN.GYB:
                    return require(`@/assets/img/gyb-themed-logo.svg`)
                case DOMAIN.MEME:
                case DOMAIN.NEWDEX:
					return require(`@/assets/img/meme-themed-logo.svg`)
			}
        },
		loadGoogleTranslate() {
			let body = document.body;
			let script = document.createElement('script');
			script.innerHTML = '';
			script.src = 'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
			body.appendChild(script);
		},
		doGTranslate(a) {
			this.isLanguageDropdownActive = false
			if (a.value)
				a = a.value;
			if (a == '')
				return;
			var b = a.split('|')[1];
			
			
			let language = this.languages.find(x => x.lang == b)
			this.selectedLanguage = language.name
			localStorage.setItem('language', this.selectedLanguage);
			var c;
			var d = document.getElementsByTagName('select');
			for (var i = 0; i < d.length; i++)
				if (d[i].className == 'goog-te-combo')
					c = d[i];
				if (document.getElementById('google_translate_element') == null || document.getElementById('google_translate_element').innerHTML.length == 0 || c.length == 0 || c.innerHTML.length == 0) {
				setTimeout(function () {
					this.doGTranslate(a)
				}, 500)
			} else {
				c.value = b;
				this.GTranslateFireEvent(c, 'change');
				this.GTranslateFireEvent(c, 'change')
			}
		},
		GTranslateFireEvent(a, b) {
			try {
				if (document.createEvent) {
					var c = document.createEvent("HTMLEvents");
					c.initEvent(b, true, true);
					a.dispatchEvent(c)
				} else {
					var d = document.createEventObject();
					a.fireEvent('on' + b, d)
				}
			} catch (e) { 
				console.log(e)
			}
		},
		async updateEndoint(e){
			this.isEndpointDropdownActive = false
			this.selectedEndpoint = this.waxEndpoints.find(x => x.endpoint == e).name
			await this.$refs.uallogin.updateEndpoint(e);
		}
    },
    mounted() {
		//for claim and myclaim pages, we dont need to check for valid client, and hide gift nft button
		let service = new UserGiftService();
        if(this.$route.name == 'Claim' || this.$route.name == 'MyClaims'){
            this.isValidClient = true
			this.allowGiftNft = true
			if(this.$route.params.giftclientid){
				service.getGiftClient(this.$route.params.giftclientid).then((res) => {
					if(res.data.isSuccess) // if not a registered client
					{
						this.isValidClient = true
						this.giftClient = res.data
                    }
                    else{
                        this.giftClient = null
                    }
				})
			}
        }
        else{
            if(this.$route.query.id){
                 service.getGiftClient(this.$route.query.id).then((res) => {
				if(res.data.isSuccess) // if registered client
				{
					this.isValidClient = true
					this.giftClient = res.data
					//this.$emit("update-clientstatus", true);
				}
				else{// if not a registered client
					this.$router.push('/')
				}
				})
				.catch((error) => {
					console.log(error)
					this.$router.push('/')
				});
            }
             this.loadChildComponent = true

        // if(!this.$route.query.id){
        //     if(this.$route.name != 'Home')
        //     this.$router.push('/')
        // }
        // else{
        //     service.getGiftClient(this.$route.query.id).then((res) => {
		// 		if(res.data.isSuccess) // if registered client
		// 		{
		// 			this.isValidClient = true
		// 			this.giftClient = res.data
		// 			//this.$emit("update-clientstatus", true);
		// 		}
		// 		else{// if not a registered client
		// 			this.$router.push('/')
		// 		}
		// 		})
		// 		.catch((error) => {
		// 			console.log(error)
		// 			this.$router.push('/')
		// 		});
		// 	}
        }
    },
    created(){
		this.loadGoogleTranslate()
        if(this.$route.name == "IframeClaim"){
            this.isIframe = true
        }
        axios.get(`${process.env.VUE_APP_BASE_API_URL}members/domain`).then((res) =>{
			this.domainSettings = JSON.parse(res.data.settings)
			this.domain = res.data.id

			// SET DOMAIN = WAX
			// this.domainSettings = JSON.parse('{"Urls":["wax.giftnft.io"],"Theme":"","WebsiteName":"wax","WebsiteUrl":"https://wax.giftnft.io","ExplorerUrl":"https://wax.atomichub.io/explorer","ImageExplorerUrl":"https://cloudflare-ipfs.com/ipfs","TxEndpoint":"https://api.wax.alohaeos.com","TxExplorerUrl":"https://waxblock.io/transaction","ClaimUrl":"https://wax.giftnft.io/claim","SendGridTemplate":"d-93ade29307164e098796ea7be9d344b0","ExpirationDays":30}')
			// this.domain = DOMAIN.WAX

			// SET DOMAIN = GYB
			//this.domainSettings = JSON.parse('{"Urls":["gyb.giftnft.io"],"Theme":"","WebsiteName":"gyb","WebsiteUrl":"https://gyb.giftnft.io","ExplorerUrl":"https://testnets.opensea.io","ImageExplorerUrl":"https://cloudflare-ipfs.com/ipfs","TxEndpoint":"https://api-staging.arkane.network/api/transactions/execute","TxExplorerUrl":"https://rinkeby.etherscan.io/tx","ClaimUrl":"https://gyb.giftnft.io/claim","SendGridTemplate":"d-73ff1e733d734edda48d99634d1a61b2","ExpirationDays":30}')
			//this.domain = DOMAIN.GYB

            // SET DOMAIN = HEDERA
			// this.domainSettings = JSON.parse('{"Urls":["hedera.giftnft.io","staging.hedera.giftnft.io","localhost"],"Theme":"","WebsiteName":"hedera","WebsiteUrl":"http://staging.hedera.giftnft.io","ExplorerUrl":"https://treasureland.market","ImageExplorerUrl":"https://cloudflare-ipfs.com/ipfs","TxEndpoint":"https://api.arkane.network/api/transactions/execute","TxExplorerUrl":"https://bscscan.com/tx","ClaimUrl":"https://newdex.giftnft.io/claim","SendGridTemplate":"d-aa4aa505a18743cf9599e5927253a311","ExpirationDays":30,"ArkaneAPIKey":"TC5DQHh3NDhWUXMrLlUzVE4/NG8wNl9GdD80cUJyWjZMJm5nYklUYXwxUzZySTRrJHw=","ChainID":"0x38","ArkaneWalletID":"1dc34504-e92f-40ad-baaf-219b2eba037e","HoldingAccount":"0x4c511AD4211CE52282fe768b44408eDe42641Aa8","AssetsAPIEndpoint":"https://bsc-azrael.arkane.network"}')
			// this.domain = DOMAIN.HEDERA

			// for WAX load original default theme
			if(this.domain != DOMAIN.WAX) {
				this.theme = this.domainSettings.WebsiteName
			}
			// set theme
			if (this.theme) {
				let htmlElement = document.documentElement
				htmlElement.setAttribute('theme', this.theme);
			}
            //set favicon
            if(this.domain == DOMAIN.MEME){
                const favicon = document.getElementById("favicon");
                favicon.href = `${window.location.origin}/favicon-meme.ico`;
            }
        })
    }
};
</script>
